<template>
  <transition
    name="router-anim"
    :enter-active-class="`animated fadeInUp`"
    mode="out-in"
    :leave-active-class="`animated fadeOut`">
    <router-view></router-view>
  </transition>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  name: 'indexWallet',
  mounted () {
    core.index()
  }
}
</script>
<style>
</style>
